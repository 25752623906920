import React from 'react';
import { Link } from 'react-router-dom';
import "../assets/styles/memos.css"

const Memos = () => {
    return (
        <div className="memo-list-container">
            <div className="memo-memo-container">
                <p>
                    <h2>Op-ed, Interviews, and Memos:</h2><br/><br/>
                    <Link to={`/memo/20231027`}>[MZ Startup (1)] Dreaming of Revitalizing Korea's Declining Textile Industry</Link>,2023-10-27, Seoul (OK News)<br/>
                    <Link to={`/memo/20230224`}>Targeting Blue Hydrogen Among the Hydrogen Trio</Link>, 
                    2023-02-24, Seoul (Chosun Daily)<br/>
                    <Link to={`/memo/20221017`}>The Rapidly Growing Voluntary Carbon Market</Link>, 
                    2022-10-17, Seoul (Chosun Daily)<br/>
                    <Link to={`/memo/20220712`}>Three requirements for Korea to embrace nuclear power</Link>, 
                    2022-07-12, Seoul (The Korea Herald)<br/>
                    <Link to={`/memo/20220603`}>New Nations and Companies Set to Lead the Emerging Energy Economy</Link>, 2022-06-03, Seoul (E-Daily)<br/>
                    <Link to={`/memo/20220601`}>Why hydrogen alliance among Korea, Japan, and Australia is important</Link>, 2022-06-01, Seoul (The Korea Herald)<br/>
                    <Link to={`/memo/20211001`}>Renewable Energy Power Imbalance: A case of Jeju and Hawaii</Link>, 2021-10-01, Seoul (Chosun Daily)<br/>
                </p>
            </div>
        </div>
    )
}
export default Memos;