import React from "react";
import "../assets/styles/projects.css";
import { Link } from 'react-router-dom';

const Projects = () => {

    return (
        <div className="projects-container">
            <p>List: Saranghae Qzaqz, Edoftomo, Bongwha Cotton, Valuation Technic, Glosus</p>

            <div className="project-item">
                <Link to={`/projects/saranghae-qazaq`}>
                    <div className="project-item-meta">
                        <h2>Saranghae Qazaq<br/>(2023-2024)<br/></h2>
                        <h2>"Made in Korea with love for Kazakhstan"</h2>    
                    </div>
                    <div className="project-item-images">
                        <div className="project-item-images-box">
                            <img src="./assets/images/projects/saranghae qazaq/sq1.jpg" alt="saranghae qazaq"/>
                        </div>
                        <div className="project-item-images-box">
                            <img src="./assets/images/projects/saranghae qazaq/sq7.jpg" alt="saranghae qazaq"/>
                        </div>
                        <div className="project-item-images-box">
                            <img src="./assets/images/projects/saranghae qazaq/sq9.jpg" alt="saranghae qazaq"/>
                        </div>
                        <div className="project-item-images-box">
                            <img src="./assets/images/projects/saranghae qazaq/sq-kz1.jpg" alt="saranghae qazaq"/>
                        </div>
                        <div className="project-item-images-box">
                            <img src="./assets/images/projects/saranghae qazaq/sq-website.jpg" alt="saranghae qazaq"/>
                        </div>
                        <div className="project-item-images-box">
                            <img src="./assets/images/projects/saranghae qazaq/sq-ks-insta2.png" alt="saranghae qazaq"/>
                        </div>
                        <div className="project-item-images-box">
                            <img src="./assets/images/projects/saranghae qazaq/sq2.jpg" alt="saranghae qazaq"/> 
                        </div>
                    </div>
                </Link>
            </div>

            <div className="project-item">
                <Link to={`/projects/edoftomo`}>
                    <div className="project-item-meta">
                        <h2>Edoftomo<br/>(2022-2023)<br/></h2>
                        <h2>Sustainable workwear crafted from recycled cotton fabric</h2>    
                    </div>
                    <div className="project-item-images">
                        <div className="project-item-images-box">
                            <img src="./assets/images/projects/edoftomo/edoftomo-main.jpg" alt="edoftomo"/>
                        </div>
                        <div className="project-item-images-box">
                            <img src="./assets/images/projects/edoftomo/edoftomo-thumb1.jpg" alt="edoftomo"/>
                        </div>
                        <div className="project-item-images-box">
                            <img src="./assets/images/projects/edoftomo/edoftomo-thumb2.jpg" alt="edoftomo"/>
                        </div>
                        <div className="project-item-images-box">
                            <img src="./assets/images/projects/edoftomo/edoftomo-lookbook-1.jpg" alt="edoftomo"/>
                        </div>
                        <div className="project-item-images-box">
                            <img src="./assets/images/projects/edoftomo/edoftomo-instagram.jpg" alt="edoftomo"/>
                        </div>
                        <div className="project-item-images-box">
                            <img src="./assets/images/projects/edoftomo/edoftomo-jakji.jpg" alt="edoftomo"/>
                        </div>
                        <div className="project-item-images-box">
                            <img src="./assets/images/projects/edoftomo/edoftomo-line-sheet3.jpg" alt="edoftomo"/>
                        </div>
                    </div>
                </Link>
            </div>

            <div className="project-item">
                <Link to={`/projects/bongwha-cotton`}>
                    <div className="project-item-meta">
                        <h2>Bongwha Cotton<br/>(2022-2023)<br/></h2>
                        <h2>Physically recycled cotton textiles for a circular fashion</h2>    
                    </div>
                    <div className="project-item-images">
                        <div className="project-item-images-box">
                            <img src="./assets/images/projects/bongwha cotton/bongwha-eco.jpg" alt="bongwha-cotton"/>
                        </div>
                        <div className="project-item-images-box">
                            <img src="./assets/images/projects/bongwha cotton/bongwha-catalogue.jpg" alt="bongwha-cotton"/>
                        </div>
                        <div className="project-item-images-box">
                            <img src="./assets/images/projects/bongwha cotton/bongwha-hs1.jpg" alt="bongwha-cotton"/>    
                        </div>
                        <div className="project-item-images-box">
                            <img src="./assets/images/projects/bongwha cotton/bongwha-ministry.jpg" alt="bongwha-cotton"/>    
                        </div>
                        <div className="project-item-images-box">
                            <img src="./assets/images/projects/bongwha cotton/bongwha-lc-thumb1.jpg" alt="bongwha-cotton"/>
                        </div>
                        <div className="project-item-images-box">
                            <img src="./assets/images/projects/bongwha cotton/bongwha-business8.jpg" alt="bongwha-cotton"/>
                        </div>
                        <div className="project-item-images-box">
                            <img src="./assets/images/projects/bongwha cotton/bongwha-production7.jpg" alt="bongwha-cotton"/>
                        </div>
                        <div className="project-item-images-box">
                            <img src="./assets/images/projects/bongwha cotton/bongwha-website3.jpg" alt="bongwha-cotton"/>
                        </div>
                        <div className="project-item-images-box">                        
                            <img src="./assets/images/projects/bongwha cotton/bongwha-exp.jpg" alt="bongwha-cotton"/>
                        </div>
                        <div className="project-item-images-box">
                            <img src="./assets/images/projects/bongwha cotton/bongwha-exp.jpg" alt="bongwha-cotton"/>
                        </div>
                    </div>
                </Link>
            </div>

            <div className="project-item">
                <Link to={`/projects/valuation-technic`}>
                    <div className="project-item-meta">
                        <h2>Insider's Valuation Technic<br/>(2022-2024)<br/></h2>
                        <h2>McKinsey & Deloitte Experts' Secret Notes on Enterprise Valuation</h2>    
                    </div>
                    <div className="project-item-images">
                        <div className="project-item-images-box">
                            <img src="./assets\images\projects\valuation technic\valuation-book1.jpg" alt="valuation"/>
                        </div>
                        <div className="project-item-images-box">
                            <img src="./assets\images\projects\valuation technic\valuation-book2.jpg" alt="valuation"/>    
                        </div>
                        <div className="project-item-images-box">
                            <img src="./assets\images\projects\valuation technic\valuation-teaching2.jpg" alt="valuation"/>
                        </div>
                        <div className="project-item-images-box">
                            <img src="./assets\images\projects\valuation technic\valuation-genesis.jpg" alt="valuation"/>
                        </div>
                        <div className="project-item-images-box">
                            <img src="./assets\images\projects\valuation technic\valuation-teaching1.jpg" alt="valuation"/>
                        </div>
                    </div>
                </Link>
            </div>

            <div className="project-item">
                <Link to={`/projects/glosus`}>
                    <div className="project-item-meta">
                        <h2>Glosus<br/>(2020-2023)<br/></h2>
                        <h2>"A spoon-full of knowledge for our sustainable planet"</h2>    
                    </div>
                    <div className="project-item-images">
                        <div className="project-item-images-box">
                            <img src="./assets\images\projects\glosus\glosus-internal1.jpg" alt="glosus"/>
                        </div>
                        <div className="project-item-images-box">
                            <img src="./assets\images\projects\glosus\glosus-newsletter1.jpg" alt="glosus"/>
                        </div>
                        <div className="project-item-images-box">
                            <img src="./assets\images\projects\glosus\glosus-intro2.jpg" alt="glosus"/>
                        </div>
                        <div className="project-item-images-box">
                            <img src="./assets\images\projects\glosus\glosus-ministry2.jpg" alt="glosus"/>
                        </div>
                        <div className="project-item-images-box">
                            <img src="./assets\images\projects\glosus\glosus-report1.jpg" alt="glosus"/>
                        </div>
                        <div className="project-item-images-box">
                            <img src="./assets\images\projects\glosus\glosus-team1.jpg" alt="glosus"/>
                        </div>
                    </div>
                </Link>
            </div>
    
        </div>
    )
}
export default Projects;