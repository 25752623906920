// import react from "react";
// import "../assets/styles/headerfooter.css";

// const Header = () => {
//     return (
//       <header className="header">
//         <div className="header-contents">
//           <a href="/portfolio" id ="logo">
//               will kwon<br/>
//               .daewook
//           </a>
//           <nav>
//               <ul>
//                   <li><a href="/portfolio/about" className="hover:text-green-950">about</a></li>
//                   <li><a href="/portfolio/projects" className="hover:text-green-950">projects</a></li>
//                   <li><a href="/portfolio/memo" className="hover:text-green-950">memo</a></li>
//               </ul>
//           </nav>
//         </div>    
//       </header>
//     );
//   };
  
//   export default Header;


import React from "react";
import { Link } from "react-router-dom";
import "../assets/styles/headerfooter.css";

const Header = () => {
  return (
    <header className="header">
      <div className="header-contents">
        <Link to="/" id="logo">
          will kwon<br/>
          .daewook
        </Link>
        <nav>
          <ul>
            <li><Link to="/about" className="hover:text-green-950">about</Link></li>
            <li><Link to="/projects" className="hover:text-green-950">projects</Link></li>
            <li><Link to="/memo" className="hover:text-green-950">memo</Link></li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;


