import React from 'react';
import "../assets/styles/about.css";


const About = () => {
    return (
        <div className="about-container">
            <div className="hello-container">
                <h1>hi, i am will!</h1><br/><br/>
                <p>
                    Sep 2024 notes &#9733;<br/><br/>
                    I moved to Boston in July, MA to attend Harvard Kennedy School's MC-MPA Master's program for 1 year. <br/><br/>
                    I will be away from work and services during this period.       
                </p><br/><br/>
                <div id="profile-picture">
                    <img src="./assets/images/Central Park.jpg" alt="Description_of_image"/>
                </div>
            </div>

            <div className="bio-cv-container">
                <div className="bio-container">
                    <h1>bio</h1><br/><br/>
                    <p>
                        Will Kwon (b.1990) is an entrepreneur and investor based in Boston MA. A professionally trained business strategist and investment advisor, he is inspired by bold ideas, elegant design, and high level of craftmanship.<br/><br/>
                        Will has been primarily working for the top executives and boards of large conglomerates across the United States, Europe, Middle East, and South Korea, he has recently started working for start-up CEOs and tech Venture Capitals as well.
                    </p>
                </div>
    
                <div className="cv-container">
                    <h1>cv</h1><br/><br/>
                    <h3>EXPERIENCE</h3><br/>
                    <p>
                        <a href="https://willkommel.framer.ai" target="_blank">Willkommel Inc.</a>, CEO, 2022-present, Seoul <br/>
                        <a href="https://www.mckinsey.com/" target="_blank">McKinsey & Comapny</a>, External Advisor, 2024, Seoul <br/>
                        <a href="https://factomind.io/" target="_blank">Factomind Technology, #Hashed</a>, Advisor, 2023-2024, Seoul <br/>
                        <a href="https://www.mckinsey.com/" target="_blank">McKinsey & Comapny</a>, Associate Partner, 2015-2023, San Francisco, Amsterdam, Seoul <br/>
                        <a href="https://www.weforum.org/" target="_blank">World Economic Forum</a>, Hub leader, 2020-2024, Seoul, South Korea <br/>
                        <a href="https://www.gnieob.com/" target="_blank">Boeing Company</a>, Analyst intern, 2013, Seoul, South Korea <br/>
                        <a href="https://www.hankyung.com/" target="_blank">The Korea Economic Daily</a>, Student reporter, 2007-2008, Seoul 
                    </p><br/><br/>
                    <h3>ACADEMICS</h3><br/>
                    <p>
                        <a href="https://www.harvard.edu/" target="_blank">Harvard University</a>, Kennedy School, MPA, 2024-2025, Boston <br/>
                        <a href="https://www.yonsei.ac.kr/en_sc/index.jsp" target="_blank">Yonsei University</a>, BA in Applied Statistics & International Studies, 2009-2015, Seoul 
                    </p><br/><br/>
                    <h3>CERTIFICATES</h3><br/>
                    <p>
                        <a href="https://www.cfainstitute.org/" target="_blank">CFA Institute</a>, Chartered Financial Analyst (CFA), 2021 
                    </p><br/><br/>
                    <h3>PUBLICATIONS</h3><br/>
                    <p>
                        Insiders' Guide to Valuation Technic, Inloud, 2024<br/>
                        Valuation: Measuring and Managing the Value of Companies (7th edition: translated), Wiley, Inloud, 2023
                    </p><br/><br/>
                    <h3>CONTACTS</h3><br/>
                    <p>
                        <a href="#">will.kwon@willkommel.com</a>
                    </p><br/><br/>                    
                </div>                
            </div>
        </div>
    )
}

export default About;







// const Home = () => {
//   return (
//     <div className="flex flex-row w-100 py-16 gap-2 overflow-auto">
//         <div className="flex flex-col w-2/5">
//             <h1 className='text-green-950 text-2xl'>hi, i am will!</h1><br/><br/>
//             <p>
//                 July 2024 notes &#9733;<br/><br/>
//                 I recently moved to Boston, MA to attend Harvard Kennedy School's MC-MPA Master's program for 1 year. <br/><br/>
//                 I will be away from work and services during this period, but I will prepare my new venture in the US and write extensively about investment ideas.       
//             </p><br/><br/>
//             <div className="w-[300px]">
//                 <img src="../assets/images/Central Park.jpg" alt="Description_of_image"/>
//             </div>
//         </div>

//         <div className="flex flex-col w-3/5 gap-8">
//             <div className="w-100">
//                 <h1 className='text-green-950 text-2xl'>bio</h1><br/><br/>
//                 <p>
//                     Will Kwon (b.1990) is an entrepreneur, designer, and investor based in Boston MA. A professionally trained business strategist and investment advisor, he is inspired by bold ideas, elegant design, and high level of craftmanship.<br/><br/>
//                     Will has been primarily working for the top executives and boards of large conglomerates across the United States, Europe, Middle East, and South Korea, he has recently started working for start-up CEOs and tech Venture Capitals as well.
//                 </p>
//             </div>

//             <div className="w-100">
//                 <h1 className='text-green-950 text-2xl'>cv</h1><br/><br/>
//                 <h3>EXPERIENCE</h3><br/>
//                 <p>
//                     <a href="https://willkommel.framer.ai" target="_blank">Willkommel Inc.</a>, CEO, 2022-present, Seoul <br/>
//                     <a href="https://www.mckinsey.com/" target="_blank">McKinsey & Comapny</a>, External Advisor, 2024, Seoul <br/>
//                     <a href="https://factomind.io/" target="_blank">Factomind Technology, #Hashed</a>, Advisor, 2023-2024, Seoul <br/>
//                     <a href="https://www.mckinsey.com/" target="_blank">McKinsey & Comapny</a>, Associate Partner, 2015-2023, San Francisco, Amsterdam, Seoul <br/>
//                     <a href="https://www.weforum.org/" target="_blank">World Economic Forum</a>, Hub leader, 2020-2024, Seoul, South Korea <br/>
//                     <a href="https://www.gnieob.com/" target="_blank">Boeing Company</a>, Analyst intern, 2013, Seoul, South Korea <br/>
//                     <a href="https://www.hankyung.com/" target="_blank">The Korea Economic Daily</a>, Student reporter, 2007-2008, Seoul 
//                 </p><br/><br/>
//                 <h3>ACADEMICS</h3><br/>
//                 <p>
//                     <a href="https://www.harvard.edu/" target="_blank">Harvard University</a>, Kennedy School, MPA, 2024-2025, Boston <br/>
//                     <a href="https://www.yonsei.ac.kr/en_sc/index.jsp" target="_blank">Yonsei University</a>, BA in Applied Statistics & International Studies, 2009-2015, Seoul 
//                 </p><br/><br/>
//                 <h3>CERTIFICATES</h3><br/>
//                 <p>
//                     <a href="https://www.cfainstitute.org/" target="_blank">CFA Institute</a>, Chartered Financial Analyst (CFA), 2021 
//                 </p><br/><br/>
//                 <h3>PUBLICATIONS</h3><br/>
//                 <p>
//                     Insiders' Guide to Valuation Technic, Inloud, 2024<br/>
//                     Valuation: Measuring and Managing the Value of Companies (7th edition: translated), Wiley, Inloud, 2023
//                 </p><br/><br/>
//                 <h3>CONTACTS</h3><br/>
//                 <p>
//                     <a href="#">will.kwon@willkommel.com</a>
//                 </p><br/><br/>                    
//             </div>                
//         </div>
//     </div>
//   );
// };

// export default Home;