import React, { useState, useEffect, useMemo } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { Link } from 'react-router-dom';

const MasonryGrid = ({ 
  projects, 
  memos,
  about,
  minColumnWidth = 230, 
  maxColumnWidth = 350,
  gridGap = 10,
  postMargin = 30,
  memoPreviewWordCount = 80
}) => {
  const [columns, setColumns] = useState(1);
  const { width, ref } = useResizeDetector();

  const calculateColumns = useMemo(() => (containerWidth) => {
    if (containerWidth <= 700) {
      // Mobile view: always 2 columns
      return 2;
    }
    
    // Desktop view: maintain current ratio
    let columnCount = Math.floor(containerWidth / minColumnWidth);
    let columnWidth = containerWidth / columnCount;
    
    if (columnWidth > maxColumnWidth) {
      columnCount = Math.max(1, Math.floor(containerWidth / maxColumnWidth));
    }
    
    return columnCount;
  }, [minColumnWidth, maxColumnWidth]);

  useEffect(() => {
    if (width) {
      setColumns(calculateColumns(width));
    }
  }, [width, calculateColumns]);

  // Randomize items only once when the component mounts, but keep about first
  const organizedItems = useMemo(() => {
    const otherItems = [...projects, ...memos];
    const randomizedOtherItems = otherItems.sort(() => Math.random() - 0.5);
    return [about[0], ...randomizedOtherItems];
  }, [projects, memos, about]);

  const generateMasonryGrid = () => {
    const columnWrappers = Array.from({ length: columns }, () => []);
    
    organizedItems.forEach((item, index) => {
      const column = index % columns;
      columnWrappers[column].push(item);
    });

    const columnWidth = `calc((100% - ${gridGap * (columns - 1)}px) / ${columns})`;

    return (
      <div className="masonry-grid" 
        style={{ 
          display: 'flex', 
          gap: `${gridGap}px`,
          justifyContent: 'center',
      }}>
        {columnWrappers.map((columnItems, columnIndex) => (
          <div key={columnIndex} className="column" style={{ flex: `0 1 ${columnWidth}` }}>
            {columnItems.map((item, itemIndex) => (
              <div key={itemIndex} className={`post ${item.id === 'bongwha-cotton' ? 'centered-text' : ''}`} 
                style={{ 
                  marginBottom: `${postMargin}px`, 
                  padding: '5px' 
              }}>
                <Link to={getItemLink(item)}>
                  {item.mainImage && (
                    <img src={item.mainImage} alt={item.title} 
                      style={{ 
                        width: '100%', 
                        height: 'auto',
                        borderRadius: '10px' 
                    }} />
                  )}
                  
                  <div>
                    <br/>
                    <p className="title" style={{color: "darkslategray"}}>{item.title}</p>
                    {item.subtitle ? (
                      <p className="description" dangerouslySetInnerHTML={{ __html: item.subtitle }}></p>
                    ) : (
                      <>
                        <br/>
                        <i className="meta">{item.meta}</i>
                        <br/><br/>
                        <p className="preview">
                          {item.bodyText.split(' ').slice(0, memoPreviewWordCount).join(' ')}
                          {item.bodyText.split(' ').length > memoPreviewWordCount ? '...' : ''}
                        </p>
                      </>
                    )}
                  </div>
                </Link>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  };

  const getItemLink = (item) => {
    if (item.id === '/') return '/about';
    if (projects.some(project => project.id === item.id)) return `/projects/${item.id}`;
    if (memos.some(memo => memo.id === item.id)) return `/memo/${item.id}`;
    return '/'; // Default route if none of the above conditions are met
  };

  return (
    <div ref={ref} className="masonry-grid-container" style={{ display: 'flex', width: '100%', justifyContent: 'center', margin: '30px auto' }}>
      {generateMasonryGrid()}
    </div>
  );
};

export default MasonryGrid;