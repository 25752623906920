import React from 'react';
import { useParams } from 'react-router-dom';
import memosData from '../data/memos.json';
import '../assets/styles/memos.css';


const MemoDetail = () => {
  const { id } = useParams();
  const memo = memosData.find(p => p.id === id);

  if (!memo) return <div>Memo not found</div>;

  return (
    <div className="memo-contents-container">
        <div className="memo-title-container">
            <h2>{memo.meta}</h2><br/>
            <h1>{memo.title}</h1>
        </div>

        <div className="memo-body-container">
          {memo.bodyText.split('\n\n').map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
          <a href={memo.websiteLink} target="_blank">
            <p>{memo.bodyStamp}</p>
          </a>
        </div>
    </div>
  );
};

export default MemoDetail;