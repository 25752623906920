import React from 'react';
import { useParams } from 'react-router-dom';
import projectsData from '../data/projects.json';
import '../assets/styles/projects.css';

const ProjectDetail = () => {
  const { id } = useParams();
  const project = projectsData.find(p => p.id === id);

  if (!project) return <div>Project not found</div>;

  // Function to replace \n with <br /> tags
  const formatText = (text) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className="project-page-container">
      <div className="project-intro">
        <div className="project-intro-text">
          <h1>{project.title}</h1>
          <h2>{project.subtitle}</h2><br /><br />
          <div>
            <p>{formatText(project.introText)}</p><br /><br />
            <a href={project.websiteLink} target="_blank" rel="noopener noreferrer">
              Visit {project.title} website
            </a>
          </div>
        </div>
        <div className="project-intro-image">
          <img src={project.mainImage} alt={project.title} className="main-image" />
        </div>
      </div>

      <div className="project-body">
        {project.bodyGroups.map((group, index) => (
          <React.Fragment key={index}>
            <div className="project-body-group">
              <h2>{group.title}</h2>
              <p>{formatText(group.description)}</p>
            </div>
            {group.images.map((image, imgIndex) => (
              <div
                key={imgIndex}
                className="project-body-group"
                style={{backgroundImage: `url(${image})`}}
              />
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default ProjectDetail;