import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import './App.css';
import About from './pages/About';
import Memos from './pages/Memos';
import Projects from './pages/Projects';
import ProjectDetail from './pages/ProjectDetail';
import MemoDetail from './pages/MemoDetail';
import MasonryGrid from './pages/MasonryGrid';
import ScrollToTop from './components/ScrollToTop';
import projectsData from './data/projects.json';
import memosData from './data/memos.json';
import aboutData from './data/about.json';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<MasonryGrid projects={projectsData} memos={memosData} about={aboutData} />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/:id" element={<ProjectDetail />} />
          <Route path="/memo" element={<Memos />} />
          <Route path="/memo/:id" element={<MemoDetail />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
