import React from "react";
import "../assets/styles/headerfooter.css";


const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-contents">
        <a href="index.html">
          © 2024 Will Kwon (・ε・)ノ
        </a>
      </div>
    </footer>
  );
};

export default Footer;
